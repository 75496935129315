import React, { useState, useEffect } from "react";
import { version } from './autobuild-version';

const buildIsNotSame = (latest, current) => {
    if (latest !== current) {
        return true;
    } else {
        return false;
    }
};

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [isLatestBuild, setIsLatestBuild] = useState(false);

        useEffect(() => {
            fetch("/meta.json")
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersion = meta.build;
                    const currentVersion = version;

                    const shouldForceRefresh = buildIsNotSame(
                        latestVersion,
                        currentVersion
                    );
                    if (shouldForceRefresh) {
                        setIsLatestBuild(false);
                        refreshCacheAndReload();
                    } else {
                        setIsLatestBuild(true);
                    }
                });
        }, []);

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            // delete browser cache and hard reload
            window.location.reload(true);
        };

        return (
            <React.Fragment>
                {isLatestBuild ? <Component {...props} /> : null}
            </React.Fragment>
        );
    }

    return ClearCacheComponent;
}

export default withClearCache;
