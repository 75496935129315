import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store/configureStore";
import '../styles/alerts.scss';
import { dispatch } from "../utilities/util";



export function Alerts() {
    const alerts = useSelector<ApplicationState, AlertItem[]>(store => store.alerts.alerts);
    if (alerts.length === 0)
        return null;


    function computeClass(alert: AlertItem) {
        if (alert.type === "danger")
            return "alert-danger";
        else if (alert.type === "success")
            return "alert-success";
        else if (alert.type === "info")
            return "alert-info";
        else if (alert.type === "warning")
            return "alert-warning";
    }

    function closeAlert(alert: AlertItem) {
        dispatch("REMOVE_TOAST", alert);
    }


    return (
        <div className="fb-alerts">
            {alerts.map((alert) => (
                <div className={'alert alert-dismissible ' + computeClass(alert)} key={alert.key} role="alert">
                    <button type="button" className="close" onClick={() => closeAlert(alert)}>
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                    <div>{alert.msg}</div>
                </div>
            ))}
        </div>
    );
}


export function showAlert(type: string, msg: string) {
    const alert = { key: Math.random(), type: type, msg: msg };
    dispatch("ADD_TOAST", alert);
    setTimeout(() => dispatch("REMOVE_TOAST", alert), 8000);
}

export interface AlertState {
    alerts: AlertItem[]
}

export interface AlertItem {
    key: string,
    type: string,
    msg: string
}

export function alertsReducer(state: AlertState | undefined, action: any) {
    if (state === undefined)
        return { alerts: [] };

    switch (action.type) {
        case 'ADD_TOAST':
            return { ...state, alerts: [...state.alerts, action.payload] };

        case 'REMOVE_TOAST':
            return { ...state, alerts: state.alerts.filter(a => a != action.payload) };

        default:
            return state;
    }
}