import { ApplicationState, store } from '../store/configureStore';
import i18n from 'i18next';

export function dispatch(type: string, payload: any, delay: boolean = false) {
    if (delay)
        setTimeout(() => store.dispatch({ type: type, payload: payload }));
    else
        store.dispatch({ type: type, payload: payload });
}

export function appState(): ApplicationState {
    return store.getState();
}

export function apiRequest<T>(method: string, url: string, identity?: string | null, data?: any, customHeaders?: any): Promise<T | null> {
    var headers: any = customHeaders || { 'Content-Type': 'application/json' };
    if (identity)
        headers["Authorization"] = "mb5 " + identity;

    return fetch(url,
        {
            method: method,
            headers: headers,
            body: data ? JSON.stringify(data) : null
        })
        .then(response => response.json() as Promise<ClientApiCall<T>>)
        .then(result => {
            if (!result.success)
                throw result;
            return result.data;
        });
}

export interface ClientApiCall<T> {
    success: boolean,
    data: T | null,
    error: string | null
}


export function makeblob(dataURL: any) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
    }
    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

export function isEmptyGuid(guid: string | undefined): boolean {
    return !guid || guid === "00000000-0000-0000-0000-000000000000";
}
















export function count(list: any, selector: any) {
    return list.reduce((cnt: number, i: number) => cnt + (selector(i) ? 1 : 0), 0);
}

const _numberSeparator = (1111).toLocaleString().indexOf(".") >= 0 ? "." : ",";
const _numberFormatterRegex = /\B(?=(\d{3})+(?!\d))/g;
const _numberSeparatorRegex = new RegExp("[" + _numberSeparator + "]", 'g');

export function formatCurrency(num: string, allowNegative: boolean) {
    let amt = parseNumber(num);
    if (!allowNegative && amt < 0)
        amt = -amt;
    if (!isNaN(amt))
        return roundCurrency(amt).toFixed(2).replace(_numberFormatterRegex, _numberSeparator);
    return "";
}

export function parseNumber(val: string, allowCurrency = true) {
    if (typeof (val) === "string") {
        val = val.trim().replace(_numberSeparatorRegex, '');
        if (allowCurrency && val[0] === "$")
            val = val.substring(1);
    }
    return parseFloat(val);
}

export function roundCurrency(number: number) {
    return Math.round(number * 100 + .00001) / 100; // number might not be accurate due to floating point precision (.254999999998), so adjust by an amount smaller than should matter
}

export function isNullOrWhiteSpace(str: string) {
    if (typeof str === 'undefined' || str === null || str === "")
        return true;
    return !(/\S/.test(str));
}

export function getImageUrl(name: string, config: { apiUrl: string}) {
    const baseUrl = config.apiUrl.split('/')[0] + "//" + config.apiUrl.split('/')[2] + "/Static/images/";
    const cacheBust = "?fms_version=";
    let url = baseUrl + name + cacheBust;
    return url;
}

export const dateFormat = new Date(2000, 10, 22).toLocaleDateString().replace("2000", "YYYY").replace("11", "MM").replace("22", "DD").replace(/\u200E/g, ''); // month 10 is novmember because javascript, \u200E is a left-to-right character that IE11 inserts
const browserMonthFirst = dateFormat.indexOf("MM") === 0;
export function parseDate(val: null | undefined | string | Date, legacy = false) {
    if (!val)
        return null;

    if (typeof (val) === "string") {
        val = val.trim();
        if (val.indexOf('T') >= 0)
            val = val.substring(0, val.indexOf('T'));

        const parts = val.split(/[./-]/);
        if (parts.length !== 3)
            return null;

        let y, m, d;
        if (parts[0].length >= 3)
            [y, m, d] = parts.map(p => parseInt(p));
        else if (browserMonthFirst || (legacy && val.indexOf("/") > 0))
            [m, d, y] = parts.map(p => parseInt(p));
        else
            [d, m, y] = parts.map(p => parseInt(p));

        if (m === 0 || m > 12)
            return null;
        if (d === 0 || d > 31 || (d > 28 && d > daysInMonth(m, y)))
            return null;
        return new Date(y, m - 1, d);
    }
    else if (!(val instanceof Date))
        val = new Date(val);
        
    if (isNaN(val.getTime()))
        return null;
    return new Date(val.getFullYear(), val.getMonth(), val.getDate());
}

export let _datePlaceholder: null | string = null;
export function datePlaceholder() {
    if (!_datePlaceholder && i18n.t('date.day') !== "date.day")
        _datePlaceholder = dateFormat.replace("DD", i18n.t('date.day')).replace("MM", i18n.t('date.month')).replace("YYYY", i18n.t('date.year'));
    return _datePlaceholder;
}

export function daysInMonth(month: number, year: number) {
    // Pass in the calendar month (2 for Februrary)
    return new Date(year, month, 0).getDate();
}

export function formatPhone(val: string | null) {
    if (!val || !val.substring)
        return "";
    val = val.replace(/[^0-9]/g, '');

    if (val.length <= 3)
        return val;
    if (val.length <= 7)
        return val.substring(0, 3) + "-" + val.substring(3);
    if (val.length <= 10)
        return val.substring(0, 3) + "-" + val.substring(3, 6) + "-" + val.substring(6);
    if (val.length === 11)
        return val.substring(0, 1) + "-" + val.substring(1, 4) + "-" + val.substring(4, 7) + "-" + val.substring(7);
    return val;
}

export function formatDate(date: Date | string | null) {
    if (!(date instanceof Date)) {
        date = parseDate(date);
        if (!date)
            return "";
    }
    return date.toLocaleDateString().replace(/\u200E/g, ''); // \u200E is for IE11
}