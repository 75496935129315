

export interface LandingState {
    loaded: boolean | undefined,
    clientId: string | undefined,
    data: LandingData,
    error: string | undefined,
    mode?: string,
    previewFormUrl?: string
}

export interface LandingData {
    featured: Array<LandingFeaturedItem>,
    events: Array<LandingFeaturedItem>,
    eventsEnabled: boolean,
    eventMonthsDisplayLength: number
}


export interface LandingFeaturedItem {
    name: string,
    description: string,
    imageUrl: string,
    imageAltText: string,
    formUrl: string,
    eventDate: string
}


export function landingReducer(state: LandingState | undefined, action: any) {
    if (state === undefined)
        return { featured: [] };

    switch (action.type) {
        case 'UPDATE_LANDING':
            return { ...state, ...action.payload };

        case 'LOAD_LANDING':
            return {
                ...state,
                data: action.payload,
                loaded: true
            };

        case 'LANDING_ERROR':
            console.error(action.payload);
            return {
                ...state,
                error: (action && action.payload && action.payload.error ? action.payload.error : null) || "An error occurred"
            };

        case 'PREVIEW_FORM':
            return {
                ...state,
                ...action.payload
            }

        default:
            return state;
    }
}
