import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store/configureStore';
import { LandingData, LandingState } from './landing-config';
import { actions } from "./landing-actions";
import FeaturedCard from './featured/featured-card';
import { Container, Row, Col } from 'react-bootstrap';
//import PreviewForm from './preview-form';
import "./landing-page.scss";
import EventRegistrations from './event-registrations';
import { resize } from '../utilities/ui';


export default function LandingPage() {
    const { loaded, data, error, mode } = useSelector<ApplicationState, LandingState>(store => store.landing);

    useState(() => {
        //Apply Style
        if (mode) {
            window.history.replaceState({ type: "form-preview" }, "Preview Form", null); // Set the {type:"form-preview"} as the current location data so we know when the browser navigates back to this state
        } else {
            var query = new URLSearchParams(window.location.search);
            document.body.style.color = query.get('color') || "#353C43";
            document.body.style.backgroundColor = query.get('bg') || "";
            var embedId = query.get("embedid");
            if (embedId) {
                document.body.classList.add("embedded");

                window.addEventListener("message", msg => {
                    if (!msg.data || msg.data.embedId !== embedId)
                        return;

                    if (msg.data.color)
                        document.body.style.color = msg.data.color;
                    if (msg.data.bg)
                        document.body.style.backgroundColor = msg.data.bg;
                    if (msg.data.cardBg)
                        Array.from(document.querySelectorAll(".card")).forEach(card => (card as HTMLElement).style.backgroundColor = msg.data.cardBg);
                    if (msg.data.resize)
                        resize();
                }, false);
            }
        }
    });

    useEffect(resize);

    if (!loaded && !error) {
        if (loaded === undefined)
            actions.load();
        return <div></div>;
    } else if (error) {
        return <div className="mb-landing-page"><div className="error">{error}</div></div>;
    }

    let featured = data.featured || [];

    let row1, row2;
    if (featured.length === 1 || featured.length === 3) {
        row1 = featured.slice(0, 1);
        row2 = featured.slice(1);
    } else {
        row1 = featured.slice(0, 2);
        row2 = featured.slice(2);
    }

    const width1 = (row1.length === 1 ? 12 : 6);
    const width2 = (row2.length > 2 ? 4 : 6);
    const isPreview = !!mode;
    
    return (
        <Container className={"mb-landing-page" + (mode === "preview" ? " is-preview" : "") + (mode === "preview-form" ? " is-preview-form" : "")}>
            {mode !== "preview-form" &&
                <Fragment>
                    <Row>
                        {row1.length > 0 &&
                            <Col xs={12} md={width1}>
                                <FeaturedCard item={row1[0]} isPreview={isPreview} />
                            </Col>
                        }
                        {row1.length > 1 &&
                            <Col xs={12} md={width1}>
                                <FeaturedCard item={row1[1]} isPreview={isPreview} />
                            </Col>
                        }
                    </Row>

                    <Row className="lower-row">
                        {row2.length > 0 &&
                            <Col xs={12} md={width2}>
                                <FeaturedCard item={row2[0]} isPreview={isPreview} />
                            </Col>
                        }
                        {row2.length > 1 &&
                            <Col xs={12} md={width2}>
                                <FeaturedCard item={row2[1]} isPreview={isPreview} />
                            </Col>
                        }
                        {row2.length > 2 &&
                            <Col xs={12} md={width2}>
                                <FeaturedCard item={row2[2]} isPreview={isPreview} />
                            </Col>
                        }
                    </Row>
                    {error &&
                        <div className="error">{error}</div>
                    }
                    {data.events && <EventRegistrations isPreview={isPreview} monthMenuLength={data.eventMonthsDisplayLength}/>}
                </Fragment>
            }

            {/*{mode === "preview-form" &&
                <PreviewForm />
            }*/}

        </Container>
    );
}