import 'bootstrap/dist/css/bootstrap.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './store/configureStore';

import withClearCache from "./clear-cache";
import { version } from './autobuild-version';

import LandingPage from './landing/landing-page';
const SettingsPage = React.lazy(() => import('./settings/settings-page'));
const WrappedSettingsPage = () => <React.Suspense fallback={<div className="loading-page"><i className="fa fa-spinner fa-spin"></i></div>}><SettingsPage /></React.Suspense>;

const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp() {
    console.log("v" + version);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact path='/settings' component={WrappedSettingsPage} />
                    <Route path='/' component={LandingPage} />
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
}

ReactDOM.render(
    <App/>,
    document.getElementById('mb-landing-page'));

