import React, { useContext, useState, useRef, useMemo, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ValidationContext from '../contexts/validation-context';
import { hasEllipsis } from "../utilities/ui";
import { formatCurrency } from '../utilities/util';
import { nullSelectStyles } from './select';
import { isTouchDevice } from '../utilities/constants';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store/configureStore';
import { SettingsState } from "../settings/settings-config";

export function FormIconInput(p) {
    const getStyle = getStyles();
    const [state, refresh] = useState(false);
    const ref = useRef({});
    const v = useContext(ValidationContext);
    const error = p.error || (v && v.show && v.getError(p.name));
    let type = "text";
    if (p.format === "digits")
        type = "number";
    if (p.format === "password")
        type = "password";

    const inFocus = ref.current === document.activeElement;

    return (
        <Validation className={"form-group " + p.className + (p.value ? " animate-on-load field-has-value" : "")} valid={!error} validationMessage={error}>
            {p.label &&
                <label className={determineLabelPlacement(p.label) + " control-label field-label-addon" + (p.disabled ? " label-disabled" : "")} style={getStyle('fieldLabels')}>{p.label}</label>
            }
            <div className="input-group">
                <div className={"input-group-addon" + (inFocus ? ' input-focus' : "")}>{p.icon}</div>
                <input
                    className="form-control"
                    disabled={p.disabled || false}
                    name={p.name}
                    value={p.value || ""}
                    onFocus={(e) => { refresh(); onFocusAnimation(e) }}
                    onChange={(e) => onInputChange(e, p)}
                    onBlur={(e) => { refresh({}); onInputChange(e, p); onBlurAnimation(e); }}
                    type={type}
                    style={getStyle('inputs')}
                    maxLength={p.maxLength}
                    placeholder={p.placeholder}
                    data-qa={p.dataQa}
                    data-keyboard={p.format === "digits" ? "numeric" : ""} // for inside the kiosk app
                    ref={ref} />
            </div>
            {p.helpText && <span style={getStyle('helpText')} className="help-block">{p.helpText}</span>}
        </Validation>
    );
}

export function FormSelect(p) {
    const getStyle = getStyles();
    //const config = useSelector(store => store.settings); //useSelector<ApplicationState, SettingsState>(store => store.settings);
    const v = useContext(ValidationContext);
    const error = p.error || (v && v.show && v.getError(p.name));
    const options = p.options; //!p.customFilter ? (p.options || []).map(o => ({ value: getKey(o) + "", label: getName(o), isDisabled: o.disabled })) : p.options;
    const selected = p.value || null; //p.isMulti ? options.filter(o => p.value && p.value.indexOf(o.value) >= 0) : options.find(o => o.value == p.value) || null;

    //const inputStyle = getStyle("inputs");
    //const styles = 
    //useMemo(() => {
    //    //if (!config.shouldUseFormStyles && !config.shouldUseSelectStyles)
    //    //    return nullSelectStyles;

    //    if (inputStyle) {
    //        return {
    //            control: base => ({ ...base, ...inputStyle }),
    //            container: base => ({ ...base, ...inputStyle }),
    //            singleValue: base => ({ ...base, ...inputStyle.color }),
    //            singleValue: base => ({ ...base, color: inputStyle.color }),
    //            menuList: base => ({ ...base, color: inputStyle.color, fontSize: inputStyle.fontSize, fontFamily: inputStyle.fontFamily, backgroundColor: inputStyle.backgroundColor }),
    //            menuPortal: (!isTouchDevice() && !p.showAsTouch && !p.inModal ? base => ({ ...base, zIndex: 9999 }) : undefined)
    //        }
    //    }
    //    return { menuPortal: (!isTouchDevice() && !p.showAsTouch ? base => ({ ...base, zIndex: 9999 }) : undefined) };
    //}, [inputStyle]);

    // The CSS rules excluded from injection include dynamically-generated
    // positioning styles, without which portaling cannot work.
    const canPortal = true; //!!(config.shouldUseFormStyles || config.shouldUseSelectStyles);

    function addMenuClasses() {
        setTimeout(function () {
            const e = document.querySelector(".ss__menu");
            if (e) {
                //if (styles)
                //    e.classList.add("use-theme");
                if (p.menuClassName)
                    e.classList.add(p.menuClassName);
            }
        }, 0);
    }

    return (
        <Validation className={"form-group " + p.className + (selected ? " animate-on-load field-has-value" : "")} valid={!error} validationMessage={error} dataQa={p.dataQa}>
            <label className={"field-label control-label select-label" + (p.labelClass ? " " + p.labelClass : "") + (p.disabled ? " label-disabled" : "")} style={getStyle('fieldLabels')}>{p.label}</label>
            <Select
                menuPlacement={p.menuPlacement ? p.menuPlacement : "auto"}
                maxMenuHeight={260}
                isSearchable={p.isSearchable !== false && !isTouchDevice() && !p.showAsTouch}
                className={"search-select" + (p.enableClear && selected ? " clear-visible" : "") + " record-payment"}
                menuPortalTarget={canPortal && !isTouchDevice() && !p.showAsTouch && !p.inModal ? document.body : ''}
                name={p.name}
                value={selected}
                onFocus={(e) => onFocusAnimation(e)}
                onBlur={(e) => onBlurDropdownAnimation(e, selected)}
                onChange={p.onChange} //{(item) => onSelectChange(item, p)}
                options={options}
                textFieldProps={{
                    label: 'Label',
                    InputLabelProps: {
                        shrink: true,
                    },
                }}
                placeholder={p.placeholder || "Select..."}
                isClearable={p.enableClear}
                isDisabled={p.disabled || false}
                classNamePrefix="ss"
                filterOption={p.customFilter}
                onMenuOpen={addMenuClasses}
                isMulti={p.isMulti}
                clearValue={() => selected = null}
                closeMenuOnSelect={p.closeMenuOnSelect !== undefined ? p.closeMenuOnSelect : true}
            />
            {p.note &&
                <span className="help-block">{p.note}</span>
            }
        </Validation>
    );
}

export function FormTooltip(props) {
    const { show, tooltip, children, placement, className, trigger, onlyIfTruncatedRef } = props;

    // OnlyIfTruncated
    const [needed, setNeeded] = useState(true);
    useEffect(() => {
        if (!onlyIfTruncatedRef || !onlyIfTruncatedRef.current) {
            return;
        } else if (show !== undefined && !show) {
            return children
        }

        function check() {
            const truncated = hasEllipsis(onlyIfTruncatedRef.current);
            if (needed !== truncated)
                setNeeded(truncated);
        }
        check();

        // If the page starts out hidden on the Home tab, the element's widths are 0 so it doesn't know to show the tooltip
        var element = onlyIfTruncatedRef.current;
        element.addEventListener("mouseenter", check, true);
        return () => element.removeEventListener("mouseenter", check, true);
    });

    if (!needed || show === false)
        return children;

    return (
        <OverlayTrigger placement={placement || "top"} trigger={trigger} rootClose={true} overlay={<Tooltip className={"fms-tooltip " + (className || "")}>{tooltip}</Tooltip>}>
            {children}
        </OverlayTrigger>
    );
}

export function Validation(props) {
    const dataQa = props.dataQa ? { "data-qa": props.dataQa } : {};

    return (
        <div className={props.className + (!props.valid ? " has-error" : "")} {...dataQa}>
            {props.children}
            <ValidationMessage valid={props.valid} validationMessage={props.validationMessage} />
        </div>
    );
}

export function ValidationMessage(props) {
    if (props.valid)
        return null;

    const getStyle = getStyles();
    return <span style={getStyle('helpText')} className="help-block has-error error-msg">{props.validationMessage}</span>
}


//Input, IconInput, CurrencyInput, Phone Input
export function onBlurAnimation(event) {
    //Material Animation
    const container = event && event.target.closest(".form-group");
    if (container) {
        if (event.target.value == "")
            container.removeAttribute("animate");

        container.removeAttribute("inFocus");
    }
}

export function onFocusAnimation(event) {
    //Material Animation
    const container = event && event.target.closest(".form-group");
    if (container) {
        if (!container.hasAttribute("animate"))
            container.setAttribute("animate", "true");

        container.setAttribute("inFocus", "true");
    }
}

export function onBlurDropdownAnimation(event, selected) {
    //Material Animation
    const container = event && event.target.closest(".form-group");
    if (container) {
        if (!selected)
            container.removeAttribute("animate");

        container.removeAttribute("inFocus");
    }
}

// const formBuilder = document.getElementById("mb-formbuilder-container");
// const builderWidth = formBuilder.offsetWidth;
export function determineLabelPlacement(label, formGroup, field) {

    function checkLabelWidth(label, formGroup, smallLabelCount, largeLabelCount) {
        if (formGroup) {
            if (label && (label.length > smallLabelCount) && (label.length < largeLabelCount)) {
                return true;
            }
        } else {
            if (label) {
                if (label.length < largeLabelCount) {
                    return "field-label";
                } else if (label.props && (Object.keys(label.props).length === 0 && label.props.constructor === Object)) {
                    return "field-label";
                } else if (label.props && label.props.label && (label.props.label.length < largeLabelCount)) { //label.props.showRequired - postalcode
                    return "field-label";
                } else if (label.props && label.props.tooltip) { //processing fee
                    return "field-label";
                } else if (label.props && label.props.form) { //registration email field
                    return "field-label";
                }
            }
            return "outside-label";
        }
    }
}

function getStyles() {
    // TODO: can't use useContext outside of functional component. Once theming is in place this should be revisited
    // const context = useContext(ThemeContext);
    // return (context && context.getStyle ? context.getStyle : getNoStyles);
    return getNoStyles;
}

function getNoStyles() {
    // Intentionally blank
}

function getKey(o) {
    if (o.key !== undefined && o.key !== null)
        return o.key;
    if (o.id !== undefined && o.id !== null)
        return o.id;
    return getName(o);
}

function getName(o) {
    if (o.name !== undefined && o.name !== null)
        return o.name;
    return o;
}

function onSelectChange(item, props, validation) {
    let value = null;
    if (item) {
        if (props.isMulti)
            value = item.map(o => o.value);
        else
            value = item.value;
    }
    if (props.onChange)
        props.onChange(props.name, value);
}

function onInputChange(event, props) {
    let value = event.target.value;

    if (props.format === "currency") {
        const parts = value.split(".");
        if (parts.length > 2)
            value = parts[0] + '.' + parts[1];

        value = value.replace(/[^0-9.,]/g, '');

        if (event.type === "blur")
            value = formatCurrency(value);
    }
    else if (props.format === "digits") {
        value = value.replace(/[^0-9]/g, '');
        if (props.maxLength && value.length > props.maxLength)
            value = value.substr(0, props.maxLength);
    } else if (props.format === "number") {
        value = value.replace(/[^0-9-.]/g, '');
        if (value.lastIndexOf('-') > 0)
            value = value[0] + value.substr(1).replace(/[-]/g, '');
        if (value.indexOf('.') !== value.lastIndexOf('.')) {
            const split = value.split('.');
            value = split.shift() + "." + split.join("");
        }
    }

    if (value !== props.value && props.onChange)
        props.onChange(props.name, value, props.index);
}