import React from "react";
import { useContext, useState, useEffect } from "react";


const ValidationContext = React.createContext({});
export default ValidationContext;

export function useValidation(validator, data, key) {
    const [context, refresh] = useState({});
    if (!context.state)
        context.state = create(context, refresh, key);

    // Validate (if things have changed)
    const state = context.state;
    if (changed(state, validator, data)) {
        state.validator = validator;
        state.data = data;
        state.errors = revalidate(validator, data);
    }

    // Add validity to parent context
    const parent = useContext(ValidationContext);

    useEffect(() => {
        if (parent && parent.children) {
            parent.children.push(state);
            return () => cleanup(parent, state); // Cleanup
        }
    }, [parent, state]);

    return state;
}

function revalidate(validator, data) {
    return validator ? validator(data) : {};
}

function create(context, refresh, key) {
    const state = {
        key: key,
        show: false,
        errors: {},
        children: []
    };

    state.getError = (name) => state.errors[name];
    state.allValid = () => allValid(state.errors, state.children);
    state.hasOwnErrors = () => Object.keys(state.errors).length > 0;
    state.hasDisplayErrors = () => state.show && !state.allValid();
    state.getDisplayError = (name) => state.show ? state.getError(name) : null;
    state.setShow = (shouldShow) => {
        if (state.show !== shouldShow) {
            state.show = shouldShow;
            refresh({ ...context });
        }
        if (state.children)
            state.children.forEach(c => c.setShow(shouldShow));
    };
    return state;
}

function cleanup(parent, context) {
    const index = parent.children.indexOf(context);
    if (index > -1)
        parent.children.splice(index, 1);
}

function allValid(errors, children) {
    if (Object.keys(errors).length !== 0)
        return false;
    return !children || children.every(c => allValid(c.errors, c.children));
}

function changed(state, validator, data) {
    if (state.validator !== validator)
        return true;

    if (state.data instanceof Array) {
        if (!(data instanceof Array) || state.data.length !== data.length)
            return true;
        return state.data.some(function (d, i) { return d !== data[i] });
    }
    return state.data !== data;
}