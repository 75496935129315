import React, { useMemo, useRef, useState } from "react";
import i18n from 'i18next';
import { LandingFeaturedItem } from "../landing-config";
import { actions } from "../landing-actions";
import DOMPurify from 'dompurify';
import { FormTooltip } from "../../components/controls";
import { checkImageCache } from "../../utilities/ui";

const defaultImage = "/static/images/mountains.png";

export interface FeaturedItemProps {
    item: LandingFeaturedItem,
    isPreview?: boolean
}


export default function FeaturedCard(props: FeaturedItemProps) {
    const { item, isPreview } = props;
    const nameRef = useRef() as any;
    const descriptonRef = useRef() as any;
    const descriptionHtml = useMemo(() => getTooltipHtml(item.description), [item.description]);
    
    return (
        <FormTooltip show={isPreview} tooltip={i18n.t('settings.not-available')} className="mb-landing-tooltip">
            <div className={"card" + (item.formUrl ? "" : " disabled")} onClick={() => !isPreview && actions.goToForm(item, isPreview)}>
                <div className="image" style={{ backgroundImage: "url('" + checkImageCache(item.imageUrl ?? defaultImage) + "')" }}></div>
                <FormTooltip tooltip={item.name} onlyIfTruncatedRef={nameRef} className="mb-landing-tooltip">
                    <div className="title" ref={nameRef}>{item.formUrl ? item.name : i18n.t('settings.no-form-available')}</div>
                </FormTooltip>
                <FormTooltip tooltip={<div dangerouslySetInnerHTML={descriptionHtml}></div>} onlyIfTruncatedRef={descriptonRef} className="mb-landing-tooltip">
                    <div className="description" dangerouslySetInnerHTML={descriptionHtml} ref={descriptonRef}></div>
                </FormTooltip>
            </div>
        </FormTooltip>
    );
}

function getTooltipHtml(html: string): any {
    var div = document.createElement("div");
    div.innerHTML = DOMPurify.sanitize(html);

    // Add spaces between nodes
    var node;
    var nodes = (document.createTreeWalker as any)(div, NodeFilter.SHOW_TEXT, null, false); // 4th argument is needed for IE11, but typescript doesn't like it
    while (node = nodes.nextNode()) {
        (node as HTMLElement).nodeValue += " ";
    }

    // Get the text and truncate it
    var text = div.textContent || "";
    if (text.length > 200)
        text = text.substring(0, 199) + "&#x2026;";

    return { __html: "<span>" + text + "</span>" };
}


