export function isTouchDevice() {
    return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
}

export function isAndroid() {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid)
        return true;
    else
        return false;
}

export function isIOS() {
    if (!!navigator.userAgent.match(/(iPad|iPhone|iPod)/i))
        return true;
    else
        return false;
}


export const ImageWarning = 90;
export const ImageLimit = 100;