import * as React from 'react';
import { useRef, useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormIconInput, FormTooltip } from '../components/controls';
import { ApplicationState } from '../store/configureStore';
import { LandingFeaturedItem, LandingState } from './landing-config';
import i18n from 'i18next';
import { actions } from "./landing-actions";


import "./landing-page.scss";
import { checkImageCache, resize } from '../utilities/ui';
import Select from 'react-select';
const defaultImage = "/static/images/mountains.png";


export default function EventRegistrations(props: { isPreview: boolean, monthMenuLength: number }) {
    const { isPreview, monthMenuLength } = props;
    const allEvents = i18n.t("events.all-events");

    const { data } = useSelector<ApplicationState, LandingState>(store => store.landing);
    const [state, setState] = useState(
        {
            filtered: [],
            search: '',
            selectedMonth: allEvents,
            selectedDropdownMonth: null,
            eventSearchResults: [],
            monthsDisplayableLength: 3,
            dropdownOptions: []
        }
    );
    const update = (c: any) => setState(s => ({ ...s, ...c }));
    const nameRef = useRef() as any;
    let selectRef = null as any;

    const months = [] as any;
    data.events?.filter(e => !e.eventDate || isWithinOneYear(e.eventDate)).forEach(event => {
        const eventMonth = event.eventDate ? getMonth(event.eventDate) : 'No Event Date';
        const eventMonthList = months.find((m: any) => m.month === eventMonth);
        if (!eventMonthList) {
            months.push({ month: eventMonth, items: [event] });
        } else {
            eventMonthList.items.push(event);
        }
    });

    const clearSelectValue = () => { selectRef?.select?.clearValue(); };

    function searchChanged(name: string, value: string) {
        resize(true);

        if (value.length < 3){
            update({ search: value || ""});
            return;
        }
        const val = (value || "").toLowerCase();
        const searchResults = data.events?.filter((event: LandingFeaturedItem) => event.name?.toLowerCase().includes(val) || event.description?.toLowerCase().includes(val));
        update({ search: value || "", eventSearchResults: searchResults || [] });
    }


    let dropdownOptions = generateDropdownOptions(months, monthMenuLength);
    useEffect(() => {
        const selectedDropdown = state.selectedMonth // dropdownOptions.find((o: any) => o.value === state.selectedMonth);
        update( {selectedDropdownMonth: selectedDropdown ? selectedDropdown : ""} );
        update( {monthsDisplayableLength: calcMonthsDisplayable(monthMenuLength)} );

        window.addEventListener('resize', function() {
            const newLength = calcMonthsDisplayable(monthMenuLength)
            if (newLength !== state.monthsDisplayableLength) {
                update( {monthsDisplayableLength:  newLength} );
                update( {dropdownOptions: generateDropdownOptions(months, monthMenuLength)} );
            }
        }, true);
    }, []);


    return (
        <div className="event-registrations">
            <h1>{i18n.t("events.events-title")}</h1>
            <div>
                <FormIconInput value={state.search} onChange={searchChanged} className="event-search" placeholder={i18n.t('events.search')} icon={<i className="fas fa-search fa-sm"></i>} dataQa="event-registrations-search" />
                {state.search.length > 2 &&
                    <div className="search-summary">
                        {i18n.t('workspace.featured-image-editor.results-for').replace("{count}", state.eventSearchResults.length.toString()).replace("{plural}", state.eventSearchResults.length !== 1 ? "s" : "").replace("{search}", state.search)}
                    </div>
                }
            </div>
            {state.search.length > 2 && state.eventSearchResults && (
                <div className="event-grid">
                    {state.eventSearchResults.map((e: LandingFeaturedItem, index: number) =>
                        <FormTooltip show={isPreview} tooltip={i18n.t('settings.not-available')} className="mb-landing-tooltip">
                            <div className="card" onClick={() => !isPreview && actions.goToForm(e, isPreview)} key={index}>
                                <div className="image" style={{ backgroundImage: "url('" + checkImageCache(e.imageUrl || defaultImage) + "')" }} />
                                <FormTooltip tooltip={e.name} onlyIfTruncatedRef={nameRef} className="mb-landing-tooltip">
                                    <div className="title" ref={nameRef}>{e.name}</div>
                                </FormTooltip>
                                <div className="description">{formatEventDate(e.eventDate)}</div>
                            </div>
                        </FormTooltip>
                    )}
                </div>
            )}
            {state.search.length < 3 && (
                <Fragment>
                    <div className="events-menu">
                        <span className={state.selectedMonth === allEvents ? "selected" : ""} onClick={() => update({ selectedMonth: allEvents })}>{allEvents}</span>
                        {months.filter((m: any) => m.month && m.month !== 'No Event Date').slice(0, state.monthsDisplayableLength).map((m: any, index: number) =>
                            <span key={index} className={state.selectedMonth === m.month ? "selected" : ""} onClick={() => { update({ selectedMonth: m.month }); clearSelectValue()} }>{m.month}</span>
                        )}
                        {dropdownOptions.length > 0 &&
                            (<Select
                                ref={ref => {selectRef = ref;}}
                                name="eventMenuDropdown"
                                className="event-menu-dropdown"
                                value={state.selectedDropdownMonth || null}
                                components={{ IndicatorSeparator: () => null }}
                                label="Select"
                                options={dropdownOptions}
                                onChange={(m: any) => { m ? update( {selectedMonth: m.value, selectedDropdownMonth: m} ) : update( {selectedDropdownMonth: m})}}
                                dataQa="Event month dropdown" 
                            />)
                        }
                    </div>
                    {months.filter((m: any) => state.selectedMonth === allEvents || state.selectedMonth === m.month).map((m: any, i: number) =>
                        <div key={m.month}>
                            <h4>{state.selectedMonth === allEvents && m.month}</h4>
                            <div className="event-grid">
                                {m.items.map((e: any, index: number) =>
                                    <FormTooltip key={index} show={isPreview} tooltip={i18n.t('settings.not-available')} className="mb-landing-tooltip">
                                        <div className={"card"} onClick={() => !isPreview && actions.goToForm(e, isPreview)}>
                                            <div className="image" style={{ backgroundImage: "url('" + checkImageCache(e.imageUrl || defaultImage) + "')" }}></div>
                                            <FormTooltip tooltip={e.name} onlyIfTruncatedRef={nameRef} className="mb-landing-tooltip">
                                                <div className="title" ref={nameRef}>{e.name}</div>
                                            </FormTooltip>
                                            <div className="description">{formatEventDate(e.eventDate)}</div>
                                        </div>
                                    </FormTooltip>
                                )}
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    );
}

function generateDropdownOptions(months: any, monthMenuLength: number) {
    const monthsDisplayableLength = calcMonthsDisplayable(monthMenuLength);

    const dropdownMonths = months.length > monthsDisplayableLength ? months.filter((m: any) => m.month && m.month !== 'No Event Date').slice(monthsDisplayableLength, monthMenuLength) : [];
    const dropdownOptions = dropdownMonths.map((m: any) => {
        return { value: m.month, key: m.month, label: <span>{m.month}</span> }
    });
    return dropdownOptions;
}

function calcMonthsDisplayable(monthMenuLength: number) {
    let availWidth = window.innerWidth;
    let monthsDisplayable = 1;
    switch (true) {
        case availWidth >= 1200:
            monthsDisplayable = 12;
            break;
        case availWidth >= 1000:
            monthsDisplayable = 8;
            break;
        case availWidth >= 900:
            monthsDisplayable = 5;
            break;
        case availWidth >= 800:
            monthsDisplayable = 4;
            break;
        case availWidth >=700:
            monthsDisplayable = 3;
            break;
        default:
            monthsDisplayable = 2;
            break;
    }
    return Math.min(monthMenuLength, monthsDisplayable);
}

function getMonth(date: string): string {
    var d = new Date(date);
    if (d.valueOf())
        return d.toLocaleString(undefined, { month: 'long' });
    return "";
}

function isWithinOneYear(event: string): boolean {
    const eventDate = new Date(event);
    const today = new Date();
    const oneYearAhead = new Date(new Date().setFullYear(today.getFullYear() + 1));
    return eventDate <= oneYearAhead && eventDate >= today;
}

function formatEventDate(date: string): string {
    var d = new Date(date);
    if (d.valueOf())
        return d.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
    return "";
}
