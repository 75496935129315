import { apiRequest, dispatch } from '../utilities/util';
import { LandingData, LandingFeaturedItem } from './landing-config';
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

export const actions = {

    load: function () {
        // Load from server
        var clientId = window.location.pathname.substr(window.location.pathname.lastIndexOf("/") + 1);
        if (clientId === "dev") {
            clientId = "24c12cf8-10f5-4026-b1e1-de9ea920e724";
        } else if (clientId.length === 0) {
            dispatch("LOAD_LANDING", { featured: [{ name:"No client id specified"}] });
            return;
        }

        var location = new URLSearchParams(window.location.search).get("location");

        loadTranslation();

        dispatch("UPDATE_LANDING", { loaded: false, clientId: clientId }, true);
        apiRequest<LandingData>("GET", "/api/v1/client/" + clientId + "?location=" + (location || "") + "&nocache=" + new Date().valueOf(), null)
            .then(result => dispatch("LOAD_LANDING", result))
            .catch(result => dispatch("LANDING_ERROR", result));
    },

    goToForm: function (item: LandingFeaturedItem, isPreview: boolean | undefined) {
        if (!item.formUrl)
            return;

        if (isPreview) {
            dispatch("PREVIEW_FORM", { mode: "preview-form", previewFormUrl: item.formUrl + "&show-back=true" });
            return;
        }

        var query = new URLSearchParams(window.location.search);
        var embedid = query.get("embedid");
        if (embedid) {
            // URL contains embedid, meaning that we're in an iframe, extract and post it along with the url
            window.parent.postMessage({ id: embedid, url: item.formUrl }, query.get("parent") || document.referrer);
            return;
        }

        // Embedid not in URL, this means we're not in an iframe so we can just redirect
        window.location.href = item.formUrl + "&show-back=true";
    },

    comeBackFromFormPreview: function () {
        dispatch("PREVIEW_FORM", { mode: "preview", previewFormUrl: null });
    },
}



function loadTranslation() {

    // support for HTML 5.1 "navigator.languages"
    var language = null;
    var languages = window.navigator.languages;
    if (Array.isArray(languages)) {
        for (var i = 0; i < languages.length; i++) {
            language = languages[i];
            if (language && language.length)
                break;
        }
    }

    // support for other well known properties in browsers
    if (!language || !language.length) {
        var keys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
        for (var i = 0; i < keys.length; i++) {
            language = (window.navigator as any)[keys[i]];
            if (language && language.length)
                break;
        }
    }

    var lang = "en";
    //Check For Spanish
    //if (language.indexOf("es") === 0) {
    //    lang = "es";
    //}

    // Load Form Translation
    fetch("/static/translations/" + lang + ".json", { method: "GET" })
        .then(response => response.json() as Promise<any>)
        .then(data => {
            i18n
                .use(initReactI18next)
                .init({
                    resources: {
                        en: {
                            translation: data
                        }
                    },
                    lng: "en",
                    fallbackLng: "en",
                    interpolation: {
                        escapeValue: false
                    }
                });

            dispatch("UPDATE_SETTINGS", {});
        });
}


