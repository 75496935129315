
export interface SettingsState {
    loaded: boolean | undefined,
    showPreview: boolean,
    saving: boolean,
    identity: string,
    directLink?: string,
    fileLimitReached: boolean,
    showValidation: boolean
    fmsSessionId: string,
    images?: Array<any>,
    currentLocation?: Location,
    embedId?: string,
    applyButtonEnabled: boolean,

    client: ClientData,
    forms: Array<FormItem>,
    fmsUrl: string
}

export interface ClientData {
    id: string,
    name: string | null,
    landingPageEnabled: boolean,
    locations: Array<Location>
}

export interface Location {
    id: string,
    name: string,
    featured: Array<FeaturedItem>,
    eventsEnabled: boolean,
    eventMonthsDisplayLength: number
}

export interface FeaturedItem {
    formId?: string,
    imageUrl?: string,
}

export interface FormItem {
    formId: string,
    name: string,
    description: string,
    categories: Array<string>,
    formUrl: string,
    status?: FormStatus
}

export interface FormStatus {
    canSubmit: boolean,
    isArchived?: boolean,
    isDeleted?: boolean,
    isFundExpired?: boolean,
    isFuture?: boolean,
    isRegistrationFull?: boolean,
    isSubmissionClosed?: boolean,
    isSubmissionFull?: boolean,
    isUnpublished?: boolean
}



const initialState = {
    loaded: undefined,
    showPreview: false,
    saving: false,
    identity: "",
    fileLimitReached: false,
    showValidation: false,
    fmsSessionId: "",
    applyButtonEnabled: true,

    client: {
        id: "",
        name: "",
        landingPageEnabled: false,
        locations: [],
    },
    forms: [],
    fmsUrl: "",
    locations: []
}

export function settingsReducer(state: SettingsState | undefined, action: any): SettingsState {
    if (state === undefined)
        return initialState;


    if (window.parent && state.embedId) {
        // If there currently isn't a scrollbar, prevent flicker
        if (document.documentElement.scrollHeight <= window.innerHeight) {
            document.body.classList.add("no-scrollbar");
            setTimeout(() => document.body.classList.remove("no-scrollbar"), 300); // remove in case we want a scrollbar
        }

        setTimeout(() => window.parent.postMessage({ embedId: state.embedId, type: "resize", height: document.documentElement.getBoundingClientRect().height }, "*"), 0);
    }

    switch (action.type) {
        case 'LOAD_CLIENT_SETTINGS':
            return {
                ...state,
                ...action.payload,
                loaded: true
            }

        case 'UPDATE_SETTINGS':
            return { ...state, ...action.payload };


        case 'UPDATE_CLIENT':
            return {
                ...state,
                client: { ...state.client, ...action.payload }
            };

        case 'UPDATE_IMAGE_LIBRARY':
            var images = action.payload?.images || state.images;
            return {
                ...state,
                images: images
            };

        default:
            return state;
    }
}