

export function smoothScrollTo(scrollTargetY, scrollPane) {

    scrollTargetY = Math.max(scrollTargetY - 110, 0); // Aim 110px above the goal
    scrollPane = scrollPane || window;

    const startingScrollY = (scrollPane === window ? scrollPane.scrollY : scrollPane.scrollTop);
    const travel = scrollTargetY - startingScrollY;
    const speed = 2000; // 2000 pixels per second
    const totalTime = Math.max(.1, Math.min(Math.abs(travel / speed), .8)) * 1000; // between 100ms and 800ms
    let start = null;

    function easing(percent) {
        if (percent < .5)
            return (-0.5 * (Math.cos(Math.PI * percent) - 1));
        return Math.sin(percent * (Math.PI / 2));
    }


    function tick(currentTime) {
        if (!start)
            start = currentTime;

        const elapsed = currentTime - start;
        const percentComplete = Math.min(elapsed / totalTime, 1);
        const currentTargetY = startingScrollY + (travel * easing(percentComplete));

        if (percentComplete < 1) {
            window.requestAnimationFrame(tick);
            scrollPane.scrollTo(0, currentTargetY);
        } else {
            scrollPane.scrollTo(0, scrollTargetY);
        }
    }
    window.requestAnimationFrame(tick);
}

export function hasEllipsis(element) {
    return (element.offsetWidth < element.scrollWidth) || (element.offsetHeight < element.scrollHeight);
}

export function resize(delay) {
    var query = new URLSearchParams(window.location.search);
    var embedId = query.get("embedid");
    if (embedId) {

        function sendResize() {
            var container = document.getElementById("mb-landing-page");
            if (container)
                window.parent.postMessage({ id: embedId, height: container.scrollHeight }, query.get("parent") || document.referrer);
        }

        if (delay)
            setTimeout(sendResize, 50);
        else
            sendResize();

        document.body.style.overflowY = "hidden"; // Hide scrollbar
        setTimeout(() => document.body.style.overflowY = null, 200);
    }
}



const uncached = {};
export function breakImageCache(url) {
    const previous = uncached[url] || url;
    const updated = url + "?nocache=" + new Date().valueOf();

    uncached[url] = updated;

    Array.from(document.querySelectorAll("img"))
        .filter(i => {
            const decoded = decodeURIComponent(i.src);
            return i.src === url || i.src === previous || decoded === url || decoded === previous;
        })
        .forEach(i => i.src = updated);

    clearBrowserCache(url);
}

export function checkImageCache(url) {
    return uncached[url] || url;
}

function clearBrowserCache(url) {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url.substr(0, url.indexOf("/", url.indexOf('.'))) + "/fms/static/refresh.html?" + encodeURIComponent(url.substr(url.indexOf("/", url.indexOf('.')) + 1));
    document.body.appendChild(iframe);

    function refreshComplete(message) {
        if (message.source !== iframe.contentWindow)
            return;

        window.removeEventListener('message', refreshComplete);
        iframe.parentNode.removeChild(iframe);
    }
    window.addEventListener('message', refreshComplete);
}